<!--
 * @Description: 商品列表模板配置
 * @Autor: WangYuan
 * @Date: 2021-09-08 19:37:59
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-09-17 14:24:01
-->
<template>
  <div class="wrap">
    <div style="padding-right: 360px">
      <phone-ctn :backColor="form.backgroundColor">
        <div class="headCon">
          <div class="headTitle" :style="{color:form.titleColor == 0 ? '#000000' : '#ffffff'}">{{ form.headName }}</div>
        </div>
      </phone-ctn>
    </div>

    <config-ctn title="顶部导航">
      <config-wrap>
        <div class="mb15 f16">样式设置</div>
        <el-form :model="form" ref="form" label-width="80px">
          <!-- <el-form-item label="页面标题" prop="name">
            <el-input v-model="form.headName" placeholder="请输入页面标题" size="small"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="顶部风格" prop="name">
            <img src="../../../assets/image/default.png" @click="selectTopType('default')" class="topImg" :class="[topType == 'default' ? 'active' : '']" style="margin-left: 105px;" />
            <img src="../../../assets/image/soak.png" @click="selectTopType('soak')" class="topImg" :class="[topType == 'soak' ? 'active' : '']" style="margin-left: 10px;" />
            <div class="f12" style="color: #969799;text-align: right;line-height: 14px;margin-top: 8px;">建议把图片/多图文设为页面第一个模块</div>
            <el-form-item v-if="topType == 'soak'" label="沉浸模式" style="color: #969799;">
              <el-radio v-model="form.soak" label="1">永久沉浸【查看示例】</el-radio>
              <el-radio v-model="form.soak" label="2">滑动恢复【查看示例】</el-radio>
            </el-form-item>
          </el-form-item> -->
          <el-form-item label="标题位置" prop="seat">
            <el-radio-group v-model="form.seat" size="small" style="margin-left: 125px;">
              <el-radio-button label="0" value="0">居中</el-radio-button>
              <el-radio-button label="1" value="1">居左</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标题颜色" prop="titleColor">
            <el-radio-group v-model="form.titleColor" size="small" style="margin-left: 125px;">
              <el-radio-button label="0" value="0">黑色</el-radio-button>
              <el-radio-button label="1" value="1">白色</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="背景颜色">
            <config-color-picker v-model="form.backgroundColor"></config-color-picker>
          </el-form-item>
        </el-form>
      </config-wrap>
    </config-ctn>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "topNav",
  data() {
    return {
      topType: 'default',
      form: {
        headName: '',
        soak: '',
        seat: '0',
        titleColor: '0',
        backgroundColor: '',
      }
    }
  },

  computed: {
    ...mapGetters(["project"]),
  },
  watch: {
    "form": {
      handler() {
        this.$store.commit('setGlobalTop', this.form)
        this.$store.commit('setUpdateGlobalTop',false)
      },
      deep: true,
    },
    "$store.state.updateGlobalTop":{
      handler(val){
       if(val){
        if(JSON.parse(this.project.configEntity.topNavigation).styles){
          this.form =JSON.parse(this.project.configEntity.topNavigation).styles;
        }else{
          this.form = this.$store.state.globalTop;
        }

       }
      },
      immediate:true,

    }
  },

  methods: {
    selectTopType(type) {
      this.topType = type;
    },
  },
  created() {

    console.log("顶部导航this.project:",this.project)

    if(this.project.configEntity&&this.project.configEntity.topNavigation){
      this.form =JSON.parse(this.project.configEntity.topNavigation).styles;
    }
    else if (this.$store.state.globalTop) {
      this.form = this.$store.state.globalTop;
    }

  }
};
</script>

<style lang="scss" scoped>
  .headCon{
    position: absolute;
     top: 33px;
  }
.edit {
  position: absolute;
  top: 33px;
  left:30%

}
.headTitle {
  width: 160px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis; // 溢出显示省略号
  display: -webkit-box;  // 弹性盒子
  -webkit-box-orient: vertical; // 上下垂直排列
  -webkit-line-clamp: 1; // 显示行数（数字可调整）
}

.topImg {
  width: 62px;
  padding: 5px;
  cursor: pointer;
}
.active {
  border: 1px solid #3894ff;
}
</style>
